// extracted by mini-css-extract-plugin
export var strike = "styles-module--strike--8F1By";
export var toggle = "styles-module--toggle--8JhiJ";
export var toggleButton = "styles-module--toggle-button--Qj-2I";
export var track = "styles-module--track--jPCKO";
export var slider = "styles-module--slider--rVEQG";
export var sliderActive = "styles-module--slider-active--OpCRV";
export var icon = "styles-module--icon--rd9FA";
export var labels = "styles-module--labels--ClqMF";
export var label = "styles-module--label--uVnJh";
export var labelActive = "styles-module--label-active--ZSvHl";
export var billingCta = "styles-module--billing-cta--B++1w";
export var ctaText = "styles-module--cta-text--MujV0";
export var ctaArrow = "styles-module--cta-arrow--q5w6r";