import clsx from 'clsx';
import { RichText } from 'prismic-reactjs';
import React from 'react';
import Button from '@components/Button';
import Toggle from '@components/Toggle';
import { CurrencyFormat } from '@utils/currencyFormat';
import {
  PrismicLinkTypeEnum,
  PrismicPricingPageDataBodyPricingPlanTableRow,
  PrismicPricingPageFragmentFragment,
  PrismicPricingPlanFragmentFragment,
} from '@utils/typings/prismic-types';
import * as styles from './styles.module.scss';

interface Props {
  pricingPage: PrismicPricingPageFragmentFragment;
  pricingPlans: PrismicPricingPlanFragmentFragment[];
  annual: boolean;
  setAnnual: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function PricingTableMobile({
  pricingPage,
  pricingPlans,
  annual,
  setAnnual,
}: Props) {
  if (!pricingPlans.length) return null;
  return (
    <>
      <div className={clsx(styles.toggle, 'font-neue-haas-text')}>
        <Toggle
          value={annual}
          toggle={() => setAnnual(!annual)}
          ctaText={pricingPage.data.billing_label.text}
        />
      </div>
      {pricingPlans.map((plan) => (
        <Plan
          key={plan.id}
          plan={plan}
          pricingPage={pricingPage}
          annual={annual}
        />
      ))}
    </>
  );
}

interface PlanProps {
  plan: PrismicPricingPlanFragmentFragment;
  pricingPage: PrismicPricingPageFragmentFragment;
  annual: boolean;
}
function Plan({ plan, pricingPage, annual }: PlanProps) {
  const {
    cta_link,
    cta_text,
    recommended,
    title,
    pricing_description,
    recommended_for,
  } = plan.data;

  return (
    <section
      className={clsx(styles.plan, recommended && styles.planRecommended)}
    >
      {recommended && (
        <div className={styles.recommended}>Recommended for Startups</div>
      )}
      <div className={styles.planDetails}>
        <h2 className={styles.planName}>{title.text}</h2>
        <PriceDisplay plan={plan} annual={annual} />
        <div className={styles.pricingDescription}>
          <span className={styles.text}>{pricing_description.text}</span>
        </div>
        <div className={styles.cta}>
          <Button
            className={styles.ctaButton}
            external={cta_link.link_type === PrismicLinkTypeEnum.Web}
            href={cta_link.url}
            useSearchParams
            size="Small"
            type="Primary"
            iconType="Arrow"
          >
            {cta_text.text}
          </Button>
        </div>
        <div className={styles.recommendedFor}>
          <span className={styles.heading}>Recommended For:</span>
          <RichText render={recommended_for.richText} />
        </div>
      </div>
      <details className={styles.details}>
        <summary>More Details</summary>
        <table className={styles.table}>
          <tbody>
            {pricingPage.data.body
              .filter(
                (
                  slice,
                ): slice is PrismicPricingPageDataBodyPricingPlanTableRow =>
                  slice.slice_type === 'pricing_plan_table_row',
              )
              .map((slice) => {
                const item = slice.items.find(
                  (item) => item.plan.id === plan.prismicId,
                );
                if (!item) return null;
                return (
                  <tr key={slice.id}>
                    <td>{slice.primary.title.text}</td>
                    <td>
                      {item.is_boolean ? (
                        <BooleanSymbol value={item.boolean} />
                      ) : (
                        <RichText render={item.text.richText} />
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </details>
    </section>
  );
}

interface PriceDisplayProps {
  plan: PrismicPricingPlanFragmentFragment;
  annual: boolean;
}
function PriceDisplay({ plan, annual }: PriceDisplayProps) {
  const {
    pricing_type,
    monthly_cost,
    annual_cost,
    monthly_range_minimum,
    monthly_range_maximum,
    annual_range_minimum,
    annual_range_maximum,
    pricing_text,
  } = plan.data;

  const currencyFormatter = new CurrencyFormat('USD', { fractionDigits: 0 });

  switch (pricing_type) {
    case 'Amount':
      return (
        <>
          <span className={styles.price}>
            {currencyFormatter.format(annual ? annual_cost : monthly_cost)}
          </span>
          <span className={styles.priceTime}>/mo</span>
        </>
      );
    case 'Range':
      return (
        <>
          <span className={styles.price}>
            {currencyFormatter.format(
              annual ? annual_range_minimum : monthly_range_minimum,
            )}
            -
            {currencyFormatter.format(
              annual ? annual_range_maximum : monthly_range_maximum,
            )}
          </span>
          <span className={styles.priceTime}>/mo</span>
        </>
      );
    case 'Text':
      return <span className={styles.priceText}>{pricing_text.text}</span>;
    default:
      return null;
  }
}

function BooleanSymbol({ value }: { value: boolean }) {
  return value ? <span>&#10004;</span> : <span>&#10008;</span>;
}
