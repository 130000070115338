// extracted by mini-css-extract-plugin
export var strike = "styles-module--strike--NVh4Q";
export var toggle = "styles-module--toggle--YELXN";
export var recommendedBanner = "styles-module--recommended-banner--LEexD";
export var recommendedBannerText = "styles-module--recommended-banner-text--cEJOh";
export var planDetails = "styles-module--plan-details--x2cvT";
export var planName = "styles-module--plan-name--JvBbF";
export var price = "styles-module--price--OdIWi";
export var priceText = "styles-module--price-text--NSYFx";
export var priceTime = "styles-module--price-time--zm5zt";
export var pricingDescription = "styles-module--pricingDescription--QFLWO";
export var text = "styles-module--text--B9TT7";
export var icon = "styles-module--icon--o6rxC";
export var cta = "styles-module--cta--NstDE";
export var ctaButton = "styles-module--cta-button--TnRbh";
export var recommendedFor = "styles-module--recommended-for--xVY5h";
export var heading = "styles-module--heading--ScJ2z";
export var recommended = "styles-module--recommended--OL1wo";
export var categoryTitle = "styles-module--category-title--G8fAG";
export var tooltip = "styles-module--tooltip--snqPO";
export var title = "styles-module--title--+jkQ9";
export var helpIcon = "styles-module--help-icon--3fHB-";