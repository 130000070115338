import clsx from 'clsx';
import React from 'react';
import Banner from '@components/Banner';
import Footer from '@components/Footer';
import Nav from '@components/Nav';
import Seo from '@components/Seo';
import {
  PrismicBannerFragmentFragment,
  NavFragmentFragment,
  FooterFragmentFragment,
} from '@typings/prismic-types';
import * as styles from './styles.module.scss';

type Props = {
  children: React.ReactNode;
  banner: PrismicBannerFragmentFragment;
  nav: NavFragmentFragment;
  footer: FooterFragmentFragment;
  metadata: {
    title?: string;
    description?: string;
    imageUrl?: string;
    lang?: string;
  };
};

export default function BasicLayout({
  children,
  banner,
  nav,
  footer,
  metadata,
}: Props) {
  return (
    <div className="typography">
      <Seo
        title={metadata.title || `Pry.co`}
        description={metadata.description || `Pry`}
        image={metadata.imageUrl}
        lang={metadata.lang}
      />
      <Banner banner={banner} />
      <Nav nav={nav} sticky={false} />
      <main className={clsx(styles.main, 'container-with-padding')}>
        {children}
      </main>
      <Footer footer={footer} />
    </div>
  );
}
