// extracted by mini-css-extract-plugin
export var strike = "styles-module--strike--vHQES";
export var toggle = "styles-module--toggle--UwybZ";
export var recommended = "styles-module--recommended--LdyJD";
export var plan = "styles-module--plan--tzQpd";
export var planRecommended = "styles-module--plan-recommended--oqVVr";
export var planDetails = "styles-module--plan-details--pY-6e";
export var planName = "styles-module--plan-name--d7gSj";
export var price = "styles-module--price--pKk37";
export var priceText = "styles-module--price-text--6ClJ1";
export var priceTime = "styles-module--price-time--w9qvL";
export var pricingDescription = "styles-module--pricingDescription--hxmsi";
export var text = "styles-module--text--MT3Tn";
export var cta = "styles-module--cta--8ZGJV";
export var ctaButton = "styles-module--cta-button--ELpdL";
export var details = "styles-module--details--ZOB5v";
export var recommendedFor = "styles-module--recommended-for--tFXpy";
export var heading = "styles-module--heading--ZgHio";
export var table = "styles-module--table--KdTGw";