import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import BasicLayout from '@components/BasicLayout';
import PricingContent from '@components/PricingContent';
import SliceZone from '@components/SliceZone';
import { useAllPrismicPricingPlan } from '@utils/hooks/useAllPrismicPricingPlan';
import linkResolver from '@utils/linkResolver';
import { PricingTemplateQuery } from '@typings/prismic-types';

type Props = PageProps<PricingTemplateQuery>;

export function PricingTemplate({ data }: Props) {
  const pricingPlans = useAllPrismicPricingPlan();

  if (!data) return null;

  const pricingPage = data.prismicPricingPage;
  const nav = data.prismicNav;
  const footer = data.prismicFooter;

  const metadata = {
    title: pricingPage.data.page_title.text,
    description: pricingPage.data.page_description.text,
    imageUrl: pricingPage.data.page_image.url,
    lang: pricingPage.lang,
  };

  return (
    <BasicLayout
      banner={data.prismicBanner}
      nav={nav}
      footer={footer}
      metadata={metadata}
    >
      <PricingContent pricingPage={pricingPage} pricingPlans={pricingPlans} />
      <SliceZone slices={pricingPage.data.body} />
    </BasicLayout>
  );
}

export const query = graphql`
  query PricingTemplate($lang: String) {
    prismicPricingPage {
      ...PrismicPricingPageFragment
    }
    prismicNav(lang: { eq: $lang }) {
      ...NavFragment
    }
    prismicFooter(lang: { eq: $lang }) {
      ...FooterFragment
    }
    prismicBanner {
      ...PrismicBannerFragment
    }
  }
`;

export default withPrismicPreview(PricingTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);
