import React, { useState } from 'react';
import { graphql } from 'gatsby';
import PricingTable from '@components/PricingTable';
import PricingTableMobile from '@components/PricingTableMobile';
import useWindowSize from '@utils/hooks/useWindowSize';
import {
  PrismicPricingPageFragmentFragment,
  PrismicPricingPlanFragmentFragment,
} from '@utils/typings/prismic-types';
import * as styles from './styles.module.scss';

interface Props {
  pricingPage: PrismicPricingPageFragmentFragment;
  pricingPlans: PrismicPricingPlanFragmentFragment[];
}
export default function PricingPlans({ pricingPage, pricingPlans }: Props) {
  const [annual, setAnnual] = useState(pricingPage.data.default_billing_annual);
  const { isMobile } = useWindowSize();
  return (
    <>
      {isMobile ? (
        <PricingTableMobile
          pricingPage={pricingPage}
          pricingPlans={pricingPlans}
          annual={annual}
          setAnnual={setAnnual}
        />
      ) : (
        <PricingTable
          pricingPage={pricingPage}
          pricingPlans={pricingPlans}
          annual={annual}
          setAnnual={setAnnual}
        />
      )}
    </>
  );
}

export const query = graphql`
  fragment PrismicPricingPlanFragment on PrismicPricingPlan {
    id
    prismicId
    type
    data {
      title {
        text
      }
      pricing_type
      monthly_cost
      annual_cost
      monthly_range_minimum
      monthly_range_maximum
      annual_range_minimum
      annual_range_maximum
      pricing_text {
        text
      }
      cta_text {
        text
      }
      cta_link {
        link_type
        url
        uid
      }
      recommended
      pricing_description {
        text
      }
      pricing_description_tooltip {
        text
      }
      recommended_for {
        richText
      }
    }
  }
`;
