import clsx from 'clsx';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import Tippy from '@tippyjs/react';
import { RichText } from 'prismic-reactjs';
import React from 'react';
import Button from '@components/Button';
import Toggle from '@components/Toggle';
import { CurrencyFormat } from '@utils/currencyFormat';
import {
  PrismicLinkTypeEnum,
  PrismicPricingPageDataBodyPricingPlanCategoryTitle,
  PrismicPricingPageDataBodyPricingPlanTableRow,
  PrismicPricingPageDataBodyPricingPlanTableRowItem,
  PrismicPricingPageFragmentFragment,
  PrismicPricingPlanFragmentFragment,
} from '@utils/typings/prismic-types';
import HelpIcon from '@icons/help.svg';
import InfoIcon from '@icons/info.svg';
import * as styles from './styles.module.scss';

interface Props {
  pricingPage: PrismicPricingPageFragmentFragment;
  pricingPlans: PrismicPricingPlanFragmentFragment[];
  annual: boolean;
  setAnnual: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function PricingTable({
  pricingPage,
  pricingPlans,
  annual,
  setAnnual,
}: Props) {
  if (!pricingPlans.length) return null;
  return (
    <table className="font-neue-haas-text">
      <thead>
        <tr>
          <th>
            <Toggle
              value={annual}
              toggle={() => setAnnual(!annual)}
              ctaText={pricingPage.data.billing_label.text}
            />
          </th>
          {pricingPlans.map((plan) => (
            <PlanHeader key={plan.id} plan={plan} annual={annual} />
          ))}
        </tr>
      </thead>
      <tbody>
        {pricingPage.data.body
          .filter((slice) =>
            ['pricing_plan_category_title', 'pricing_plan_table_row'].includes(
              slice.slice_type,
            ),
          )
          .map((slice) => {
            switch (slice.slice_type) {
              case 'pricing_plan_category_title':
                return (
                  <tr key={slice.id}>
                    <CategoryTitle
                      title={
                        (
                          slice as PrismicPricingPageDataBodyPricingPlanCategoryTitle
                        ).primary.category_title.text
                      }
                    />
                    {pricingPlans.map((plan) => (
                      <td
                        key={plan.prismicId}
                        className={clsx(
                          plan.data.recommended && styles.recommended,
                        )}
                      />
                    ))}
                  </tr>
                );
              case 'pricing_plan_table_row':
                return (
                  <TableRow
                    key={slice.id}
                    slice={
                      slice as PrismicPricingPageDataBodyPricingPlanTableRow
                    }
                    plans={pricingPlans}
                  />
                );
              default:
                return null;
            }
          })}
      </tbody>
    </table>
  );
}

interface PlanHeaderProps {
  plan: PrismicPricingPlanFragmentFragment;
  annual: boolean;
}
function PlanHeader({ plan, annual }: PlanHeaderProps) {
  const {
    cta_link,
    cta_text,
    recommended,
    title,
    pricing_description,
    pricing_description_tooltip,
    recommended_for,
  } = plan.data;

  return (
    <th className={clsx(recommended && styles.recommended)}>
      <div className={styles.recommendedBanner}>
        {recommended && (
          <span className={styles.recommendedBannerText}>
            Recommended for Growth
          </span>
        )}
      </div>
      <div className={styles.planDetails}>
        <h2 className={styles.planName}>{title.text}</h2>
        <h3>
          <PriceDisplay plan={plan} annual={annual} />
        </h3>
        <div className={styles.pricingDescription}>
          <span className={styles.text}>{pricing_description.text}</span>
          {pricing_description_tooltip.text && (
            <Tippy
              content={
                <span className={styles.tooltip}>
                  {pricing_description_tooltip.text}
                </span>
              }
              theme="light"
              placement="bottom"
              // Hide when no content
              {...(!pricing_description_tooltip.text && { visible: false })}
            >
              <span className={styles.icon}>
                <InfoIcon />
              </span>
            </Tippy>
          )}
        </div>
        <div className={styles.cta}>
          <Button
            className={styles.ctaButton}
            external={cta_link.link_type === PrismicLinkTypeEnum.Web}
            href={cta_link.url}
            useSearchParams
            size="Small"
            type={recommended ? 'Primary' : 'Secondary'}
            iconType="Arrow"
          >
            {cta_text.text}
          </Button>
        </div>
        <div className={styles.recommendedFor}>
          <span className={styles.heading}>Recommended For:</span>
          <RichText render={recommended_for.richText} />
        </div>
      </div>
    </th>
  );
}

interface PriceDisplayProps {
  plan: PrismicPricingPlanFragmentFragment;
  annual: boolean;
}
function PriceDisplay({ plan, annual }: PriceDisplayProps) {
  const {
    pricing_type,
    monthly_cost,
    annual_cost,
    monthly_range_minimum,
    monthly_range_maximum,
    annual_range_minimum,
    annual_range_maximum,
    pricing_text,
  } = plan.data;

  const currencyFormatter = new CurrencyFormat('USD', { fractionDigits: 0 });

  switch (pricing_type) {
    case 'Amount':
      return (
        <>
          <span className={styles.price}>
            {currencyFormatter.format(annual ? annual_cost : monthly_cost)}
          </span>
          <span className={styles.priceTime}>/mo</span>
        </>
      );
    case 'Range':
      return (
        <>
          <span className={styles.price}>
            {currencyFormatter.format(
              annual ? annual_range_minimum : monthly_range_minimum,
            )}
            -
            {currencyFormatter.format(
              annual ? annual_range_maximum : monthly_range_maximum,
            )}
          </span>
          <span className={styles.priceTime}>/mo</span>
        </>
      );
    case 'Text':
      return <span className={styles.priceText}>{pricing_text.text}</span>;
    default:
      return null;
  }
}

interface CategoryTitleProps {
  title: string;
}
function CategoryTitle({ title }: CategoryTitleProps) {
  return <td className={styles.categoryTitle}>{title}</td>;
}

interface TableRowProps {
  slice: PrismicPricingPageDataBodyPricingPlanTableRow;
  plans: PrismicPricingPlanFragmentFragment[];
}
function TableRow({ slice, plans }: TableRowProps) {
  return (
    <tr className="font-medium">
      <td className={styles.title}>
        <span>{slice.primary.title.text}</span>
        {!!slice.primary.tooltip.text && (
          <Tippy
            content={
              <span className={styles.tooltip}>
                {slice.primary.tooltip.text}
              </span>
            }
            theme="light"
            // Hide when no content
            {...(!slice.primary.tooltip.text && { visible: false })}
          >
            <span className={styles.helpIcon}>
              <HelpIcon />
            </span>
          </Tippy>
        )}
      </td>

      {plans.map((plan) => {
        const item = slice.items.find(
          (item) => item.plan.id === plan.prismicId,
        );
        return (
          <td
            key={plan.prismicId}
            className={clsx(plan.data.recommended && styles.recommended)}
          >
            <TableRowContent item={item} />
          </td>
        );
      })}
    </tr>
  );
}

interface TableRowContentProps {
  item?: PrismicPricingPageDataBodyPricingPlanTableRowItem;
}
function TableRowContent({ item }: TableRowContentProps) {
  if (!item) return null;

  if (item.is_boolean) {
    return <BooleanSymbol value={item.boolean} />;
  } else {
    return <RichText render={item.text.richText} />;
  }
}

function BooleanSymbol({ value }: { value: boolean }) {
  return value ? <span>&#10004;</span> : <span>&#10008;</span>;
}
