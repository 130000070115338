import { graphql, useStaticQuery } from 'gatsby';
import { UseAllPrismicPricingPlanQuery } from '@utils/typings/prismic-types';

export function useAllPrismicPricingPlan() {
  const query = useStaticQuery<UseAllPrismicPricingPlanQuery>(graphql`
    query UseAllPrismicPricingPlan {
      allPrismicPricingPlan {
        nodes {
          ...PrismicPricingPlanFragment
        }
      }
    }
  `);

  query.allPrismicPricingPlan.nodes.sort((a, b) => {
    if (b.data.pricing_type === 'Text') return -1;
    if (a.data.pricing_type === 'Text') return 1;
    const aComparisonPrice =
      a.data.pricing_type === 'Amount'
        ? a.data.monthly_cost
        : a.data.monthly_range_maximum;
    const bComparisonPrice =
      b.data.pricing_type === 'Amount'
        ? b.data.monthly_cost
        : b.data.monthly_range_maximum;
    return aComparisonPrice > bComparisonPrice ? 1 : -1;
  });

  return query.allPrismicPricingPlan.nodes;
}
