import clsx from 'clsx';
import React from 'react';
import arrowIcon from '@images/arrow-large-left-curve-2.png';
import ArrowRightIcon from '@icons/arrow-right-2.svg';
import * as styles from './styles.module.scss';

interface ToggleProps {
  value: boolean;
  toggle: () => void;
  ctaText?: string;
}
export default function Toggle({ value, toggle, ctaText }: ToggleProps) {
  return (
    <div className={styles.toggle}>
      <ToggleButton
        on={value}
        onClick={toggle}
        falseLabel="Monthly"
        trueLabel="Annually"
      />
      {ctaText && (
        <div className={styles.billingCta}>
          <span className={styles.ctaText}>{ctaText}</span>
          <img className={styles.ctaArrow} src={arrowIcon} />
        </div>
      )}
    </div>
  );
}

interface ToggleButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  on: boolean;
  trueLabel: string;
  falseLabel: string;
}
function ToggleButton({
  on,
  trueLabel,
  falseLabel,
  ...props
}: ToggleButtonProps) {
  return (
    <div className={styles.toggleButton}>
      <button className={styles.track} {...props}>
        <div className={clsx(styles.slider, on && styles.sliderActive)}>
          <div className={styles.icon}>
            <ArrowRightIcon />
          </div>
        </div>
      </button>
      <div className={styles.labels}>
        <div className={clsx(styles.label, on && styles.labelActive)}>
          {trueLabel}
        </div>
        <div className={clsx(styles.label, !on && styles.labelActive)}>
          {falseLabel}
        </div>
      </div>
    </div>
  );
}
