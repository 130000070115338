import { isSsr } from './isSsr';

export class CurrencyFormat {
  compact: boolean;
  numberFormat: Intl.NumberFormat;
  currencySymbol: string;
  decimalSymbol: string;
  separatorSymbol: string;

  constructor(
    currencyCode: string,
    options: { fractionDigits?: number; compact?: boolean } = {},
  ) {
    this.compact = options.compact;
    this.numberFormat = this.getNumberFormat(currencyCode, options);
    this.currencySymbol = this.getCurrencySymbol();
    this.decimalSymbol = this.getDecimalSymbol();
    this.separatorSymbol = this.getSeparatorSymbol();
  }

  getNumberFormat(
    currencyCode: string,
    options: { fractionDigits?: number; compact?: boolean },
  ) {
    const { fractionDigits, compact } = options;

    const numberFormatOptions: Intl.NumberFormatOptions = {
      style: 'currency',
      currency: currencyCode,
      ...(fractionDigits !== undefined && {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      }),
      ...(compact !== undefined && { notation: 'compact' }),
    };

    const languages = isSsr ? 'en-US' : [...navigator.languages];

    // `currencyDisplay: "narrowSymbol"` is not supported by all browsers. Fall back to symbol if unsupported
    try {
      return new Intl.NumberFormat(languages, {
        ...numberFormatOptions,
        currencyDisplay: 'narrowSymbol',
      });
    } catch (e: any) {
      if (e.message === 'Incorrect locale information provided') {
        try {
          return new Intl.NumberFormat('en-US', {
            ...numberFormatOptions,
            currencyDisplay: 'narrowSymbol',
          });
        } catch (e) {
          return new Intl.NumberFormat('en-US', {
            ...numberFormatOptions,
            currencyDisplay: 'symbol',
          });
        }
      } else if (
        e.message ===
        'Failed to initialize NumberFormat since used feature is not supported in the linked ICU version'
      ) {
        return new Intl.NumberFormat(languages, {
          ...numberFormatOptions,
          notation: 'standard',
          currencyDisplay: 'symbol',
        });
      } else {
        return new Intl.NumberFormat(languages, {
          ...numberFormatOptions,
          currencyDisplay: 'symbol',
        });
      }
    }
  }

  getCurrencySymbol() {
    return this.numberFormat.formatToParts(0)[0].value;
  }

  getSeparatorSymbol() {
    return this.numberFormat
      .formatToParts(1000000)
      .find((p) => p.type === 'group')?.value;
  }

  getDecimalSymbol() {
    return this.numberFormat.formatToParts(0).find((p) => p.type === 'decimal')
      ?.value;
  }

  format(amount: number) {
    if (Object.is(amount, -0)) {
      // This is a little workaround to ensure we display -$0 as 0, which is needed when we display expenses as negative
      amount = 0;
    }

    if (
      this.numberFormat.resolvedOptions().currencyDisplay === 'narrowSymbol'
    ) {
      // ES2020 spec support
      return this.numberFormat.format(amount);
    }

    // The browser (looking at you, Safari) implements the only the ES2019 spec,
    // which doesn't support currencyDisplay: "narrowSymbol" or notation: "compact"
    const partsWithNarrowSymbol = this.numberFormat
      .formatToParts(amount)
      .map((p) => {
        switch (p.type) {
          case 'currency':
            return { ...p, value: currencyCodes[p.value] || p.value };
          case 'literal':
            return { ...p, value: '' };
          default:
            return p;
        }
      });

    if (!this.compact) {
      return partsWithNarrowSymbol.map((p) => p.value).join('');
    }

    const currency = partsWithNarrowSymbol
      .filter((p) => ['minusSign', 'currency'].includes(p.type))
      .map((p) => p.value)
      .join('');
    const compactNumber = formatNumberCompact(amount);
    return `${currency}${compactNumber}`;
  }
}

// Stole this questionable-looking code from stackoverflow
// We only output positive number because we get the minus sign from NumberFormat #positiveVibesOnly
function formatNumberCompact(value: number) {
  const num = Number(value);
  const absNum = Math.abs(num);
  const numLength = Math.floor(absNum).toString().length;
  const symbol = ['K', 'M', 'B', 'T', 'Q'];
  const symbolIndex = Math.floor((numLength - 1) / 3) - 1;
  const abbrv = symbol[symbolIndex] || symbol[symbol.length - 1];
  let divisor = 0;
  if (numLength > 15) divisor = 1e15;
  else if (numLength > 12) divisor = 1e12;
  else if (numLength > 9) divisor = 1e9;
  else if (numLength > 6) divisor = 1e6;
  else if (numLength > 3) divisor = 1e3;
  else return absNum.toString();
  return `${(absNum / divisor).toFixed(divisor && 1)}${abbrv}`;
}

// Not comprehensive, but it's only for a small error case and it's not worth the effort
const currencyCodes: { [key: string]: string } = {
  USD: '$',
  AUD: '$',
  CAD: '$',
  GBP: '£',
  EUR: '€',
  JPY: '¥',
  RUB: '₽',
  RON: 'lei',
  BRL: 'R$',
  PHP: '₱',
  SGD: '$',
  INR: '₹',
};
