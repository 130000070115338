import React from 'react';
import { graphql } from 'gatsby';
import PricingPlans from '@components/PricingPlans';
import {
  PrismicPricingPageFragmentFragment,
  PrismicPricingPlanFragmentFragment,
} from '@utils/typings/prismic-types';
import * as styles from './styles.module.scss';

interface Props {
  pricingPage: PrismicPricingPageFragmentFragment;
  pricingPlans: PrismicPricingPlanFragmentFragment[];
}
export default function PricingContent({ pricingPage, pricingPlans }: Props) {
  return (
    <div className={styles.content}>
      <h1 className={styles.title}>{pricingPage.data.title.text}</h1>
      <div className={styles.plans}>
        <PricingPlans pricingPage={pricingPage} pricingPlans={pricingPlans} />
      </div>
    </div>
  );
}

export const query = graphql`
  fragment PrismicPricingPageFragment on PrismicPricingPage {
    _previewable
    id
    prismicId
    type
    url
    lang
    alternate_languages {
      id
      lang
      type
    }
    data {
      page_title {
        text
      }
      page_image {
        url
        alt
      }
      page_description {
        text
      }
      title {
        text
      }
      default_billing_annual
      billing_label {
        text
      }
      body {
        ... on PrismicPricingPageDataBodyPricingPlanCategoryTitle {
          id
          slice_type
          slice_label
          primary {
            category_title {
              text
            }
          }
        }
        ... on PrismicPricingPageDataBodyPricingPlanTableRow {
          id
          slice_type
          slice_label
          primary {
            title {
              text
            }
            tooltip {
              text
            }
          }
          items {
            plan {
              id
            }
            is_boolean
            text {
              richText
            }
            boolean
          }
        }
        ... on PrismicPricingPageDataBodyCtaBanner {
          id
          slice_type
          slice_label
          primary {
            background
            heading {
              text
            }
            primary_cta_icon
            primary_cta_label
            primary_cta_link {
              link_type
              url
              uid
            }
            secondary_cta_icon
            secondary_cta_label
            secondary_cta_link {
              link_type
              url
              uid
            }
            vertical_margin
          }
        }
        ...PricingGridPricingSlice
        ...PricingFaqSlice
      }
    }
  }
`;
